import React, { FormEvent } from 'react';

import type { GameOptionsType } from '../types/game.types';
import GameCreationOptions from './GameCreationOptions';


interface CreateProps {
  onCreate: (gameOptions: GameOptionsType) => void;
}

function GameCreation(props: CreateProps) {
  const [gameOptions, setGameOptions] = React.useState<GameOptionsType>({
    maxPlayers: 4,
    filteredCards: 0,
    canDrawToTable: true,
    canCollectAllRevealed: true,
  });

  const createGame = (e: FormEvent) => {
    props.onCreate(gameOptions);

    e.preventDefault();
    return false;
  };

  return (
    <form className="col-sm-12 col-md-12 col-lg-auto" onSubmit={createGame}>
      <div className="form-group">
        <label htmlFor="maxPlayers">Players</label>
        <select
          value={gameOptions.maxPlayers}
          onChange={(e) => setGameOptions({
            ...gameOptions,
            maxPlayers: parseInt(e.currentTarget.value, 10),
          })}
          id="maxPlayers"
          className="form-control"
        >
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          {/* only allow 8 players at this point, due to UI restrictions */}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="filteredCards">Cards</label>
        <select
          value={gameOptions.filteredCards}
          onChange={(e) => setGameOptions({
            ...gameOptions,
            filteredCards: parseInt(e.currentTarget.value, 10) || 0,
          })}
          id="filteredCards"
          className="form-control"
        >
          <option value={0}>ALL</option>
          <option value={1}>3s and up</option>
          <option value={2}>4s and up</option>
          <option value={3}>5s and up</option>
          <option value={4}>6s and up</option>
          <option value={5}>7s and up</option>
          <option value={6}>8s and up</option>
          <option value={7}>9s and up</option>
          <option value={8}>10s and up</option>
        </select>
      </div>
      <GameCreationOptions options={gameOptions} onOptionUpdate={setGameOptions} />
      <div className="form-group text-center">
        <button className="btn btn-outline-primary btn-lg px-4">
          Create a game
        </button>
      </div>
    </form>
  );
}

export default GameCreation;
