import type { AppConfig } from '../types/config.types';

const env = process.env.NODE_ENV || "development";

let config: AppConfig;

switch (env) {
  case 'development': {
    config = require('./development').default;
    break;
  }
  case 'production': {
    config = require('./production').default;
    break;
  }
  default:
    // no config sadly...
    // todo: should we throw?
}

export default config!;
