import React from 'react';

import { getGameUrl } from '../utils';
import type { GameOptionsType } from '../types/game.types';


interface ToolbarProps {
  gameId: string;
  gameOptions: GameOptionsType;
  totalPlayers: number;
  onExitGame: () => void;
}

function GameToolbar({ gameId, onExitGame, totalPlayers = 0, gameOptions }: ToolbarProps) {
  const [showInput, setShowInput] = React.useState(false);
  return (
    <div className="p-3 d-flex align-items-center justify-content-between gameToolbar">
      <button
        type="button"
        className="btn btn-outline-danger btn-sm px-3"
        onClick={onExitGame}
      >
        ⬅ &nbsp; exit game
      </button>

      <div className="d-flex align-items-center">
        {showInput
          ? (
            <>
              <small className="text-nowrap mr-3">copy & share this link with your friends:</small>
              <input
                className="form-control form-control-sm mr-3"
                value={getGameUrl(gameId)}
                autoFocus
                readOnly
                onFocus={(e) => e.target.select()}
              />
              <button
                type="button"
                className="btn btn-outline-info text-nowrap btn-sm px-3"
                onClick={() => setShowInput(false)}
              >
                close
              </button>
            </>
          ): (
            <>
              <small className="text-nowrap mr-3">Cards: {gameOptions.filteredCards ? `${gameOptions.filteredCards + 2}+` : 'ALL'}</small>
              <small className="text-nowrap mr-3">Players: {totalPlayers}/{gameOptions.maxPlayers}</small>
              <button
                type="button"
                className="btn btn-outline-info text-nowrap btn-sm px-3"
                onClick={() => setShowInput(true)}
                disabled={totalPlayers >= gameOptions.maxPlayers}
              >
                invite players
              </button>
            </>
          )
        }
      </div>
    </div>
  );
}

export default GameToolbar;
