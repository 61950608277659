interface UIPlayerPos {
  position: 'absolute';
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  transform?: string;
}

export type UIPlayerAlignment = 'left' | 'center' | 'right';
export type UICardPos = 'top' | 'bottom';


const playersUIPos: UIPlayerPos[] = [
  // current player
  { position: 'absolute', bottom: '16px', left: '50%', transform: 'translateX(-50%)' },  // 0: current player
  // other players                                                                       // 1-7: other players (totalOthers.otherPlayerIndex)
  { position: 'absolute', bottom: '16px', left: '16px' },                                // 1:                        6.1, 7.1
  { position: 'absolute', top: '50%', left: '16px', transform: `translateY(-50%)` },     // 2:         3.1, 4.1, 5.1, 6.2, 7.2
  { position: 'absolute', top: '16px', left: '16px' },                                   // 3:    2.1,      4.2, 5.2, 6.3, 7.3
  { position: 'absolute', top: '16px', left: '50%', transform: `translateX(-50%)` },     // 4: 1,      3.2,      5.3,      7.4
  { position: 'absolute', top: '16px', right: '16px' },                                  // 5:    2.2,      4.3, 5.4, 6.4, 7.5
  { position: 'absolute', top: '50%', right: '16px', transform: `translateY(-50%)` },    // 6:         3.3, 4.4, 5.5, 6.5, 7.6
  { position: 'absolute', bottom: '16px', right: '16px' },                               // 7:                        6.6, 7.7
];

/**
 * n (number of other players) & idx (other player index, 0-based) => P (ui position index, 1-based)
 * deviation from middle = ceil(sqrt(n-1))
 * range = middle +- dev (start = middle - dev, end = middle + dev)
 * spread = 2 * dev
 * pos = middle + (idx as % of [-1,1] * dev)
 * or (equivalent)
 * pos = start + (idx as % of total * spread)
 *
 * @param total  The total number of other players
 * @param idx    The other player's index
*/
export function playerUIIdx(total: number, idx: number) {
  // maximum absolute deviation
  const dev = Math.ceil(Math.sqrt(total-1));
  const pctDev = (total === 1) ? 0 : (2 * idx/(total-1) - 1); // => [-1..1]

  return 4 + Math.round(dev * pctDev);

  // or
  // const spread = 2 * dev;
  // const pct = idx/(total-1) || 0; // => [0..1]
  // const start = 4 - dev;
  // return start + Math.round(pct * spread);
}

export function getPlayerUIPos(uiPos: number = 0) {
  return playersUIPos[uiPos];
}

export function getPlayerUIAlignment(uiPos: number = 0): UIPlayerAlignment {
  if (playersUIPos[uiPos].right) {
    return 'right';
  } else if (playersUIPos[uiPos].left === '50%') {
    return 'center';
  }

  return 'left';
}


const cardsUIPos: UICardPos[] = [
  'top', 'top', 'top', 'bottom', 'bottom', 'bottom', 'bottom', 'top'
];

export function getCardsUIPos(uiPos: number = 0): UICardPos {
  return cardsUIPos[uiPos] || 'top';
}
