import * as React from 'react';

import type { GameOptionsType } from '../types/game.types';


interface Props {
  options: GameOptionsType;
  onOptionUpdate: (options: GameOptionsType) => void;
}

function GameCreationOptions({ options, onOptionUpdate }: Props) {
  const [moreOptions, toggleMoreOptions] = React.useState(false);

  return (
    <>
      <div className="form-group form-check">
        <input
          type="checkbox"
          checked={moreOptions}
          onChange={() => toggleMoreOptions(!moreOptions)}
          id="moreOptions"
          className="form-check-input"
        />
        <label className="form-check-label" htmlFor="moreOptions">
          More options
        </label>
      </div>
      {moreOptions && (
        <div className="pl-3">
          <div className="form-group form-check">
            <input
              type="checkbox"
              checked={options.canDrawToTable}
              onChange={() => onOptionUpdate({ ...options, canDrawToTable: !options.canDrawToTable })}
              id="canDrawToTable"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="canDrawToTable">
              Cards can be drawn to the table
            </label>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              checked={options.canDiscardFromDeck}
              onChange={() => onOptionUpdate({ ...options, canDiscardFromDeck: !options.canDiscardFromDeck })}
              id="canDiscardFromDeck"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="canDiscardFromDeck">
              Cards can be discarded from deck
            </label>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              checked={options.canCollectAllRevealed}
              onChange={() => onOptionUpdate({ ...options, canCollectAllRevealed: !options.canCollectAllRevealed })}
              id="canCollectAllRevealed"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="canCollectAllRevealed">
              Players can win hands by collecting all revealed cards
            </label>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              checked={options.canFoldHand}
              onChange={() => onOptionUpdate({ ...options, canFoldHand: !options.canFoldHand })}
              id="canFoldHands"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="canFoldHands">
              Players can fold hands
            </label>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              checked={options.canPickFromTable}
              onChange={() => onOptionUpdate({ ...options, canPickFromTable: !options.canPickFromTable })}
              id="canPickFromTable"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="canPickFromTable">
              Players can pick cards from the table
            </label>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              checked={options.canPickFromPlayer}
              onChange={() => onOptionUpdate({ ...options, canPickFromPlayer: !options.canPickFromPlayer })}
              id="canPickFromPlayer"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="canPickFromPlayer">
              Players can pick cards from others
            </label>
          </div>
        </div>
      )}
    </>
  );
}

export default GameCreationOptions;
