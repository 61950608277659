import React from 'react';

import './App.css';
import Home from './home/Home';
import Game from './game/Game';
import { getGameIdFromUrl } from './utils';

import { useWebService } from './ws/ws';
import gameActions from './types/actions';

import type {
  MsgGameCreate,
  MsgGameJoin,
  MsgGeneric,
  RespError,
  RespGameCreateOrJoin,
  RespGameData,
} from './types/actions.types';
import type { GameOptionsType, GameType, UserType } from './types/game.types';


function App() {
  const [player, setPlayer] = React.useState<UserType>({ name: '' });
  const [gameId, setGameId] = React.useState<string>();
  const [gameData, setGameData] = React.useState<GameType>();

  const { connect, disconnect, send } = useWebService((data) => {
    switch (data.type) {
      case gameActions.CREATE_GAME:
      case gameActions.CONNECT_TO_GAME:
        const { gameId: newGameId, playerId } = data as RespGameCreateOrJoin;

        setGameId(newGameId);
        setPlayer((playerData: UserType) => ({
          ...playerData,
          playerId,
        }));
        break;

      case gameActions.GET_ENTIRE_GAME:
        setGameData((data as RespGameData).game);
        break;

      case gameActions.ERROR:
        console.error((data as RespError).message);
        alert((data as RespError).message);
        break;

      default:
        // no action
    }
  });

  const connectAndSend = React.useCallback((data: MsgGeneric) => {
    connect(
      () => {
        send(data);
      },
      () => {
        setGameId(undefined);
        setGameData(undefined);
      }
    );
  }, [connect, send]);

  const onCreate = React.useCallback((gameOptions: GameOptionsType) => {
    connectAndSend({ type: gameActions.CREATE_GAME, player, gameOptions } as MsgGameCreate);
  }, [connectAndSend, player]);

  const onJoin = React.useCallback((existingGameId: string) => {
    connectAndSend({ type: gameActions.CONNECT_TO_GAME, player, gameId: existingGameId } as MsgGameJoin);
  }, [connectAndSend, player]);

  React.useEffect(() => {
    let gameFromUrl;
    if (player.name && (gameFromUrl = getGameIdFromUrl())) {
      onJoin(gameFromUrl);
    }
  }, [player.name]);

  return (
    <div className="App">
      {gameId && gameData
        ? (
          <Game
            gameId={gameId || ''}
            gameData={gameData}
            player={player}
            send={send}
            onExitGame={disconnect}
          />
        ) : (
          <Home
            onCreate={onCreate}
            onJoin={onJoin}
            onUpdatePlayer={setPlayer}
            preventStart={!player.name}
          />
        )
      }
    </div>
  );
}

export default App;
