import React from 'react';

import './Table.scss';
import Card from './Card';

interface TableProps {
  tableCards: number[];
  deck: number;
  onReset: () => void;
  onDraw: (cards: number) => void;
  onDrawAll: (cards: number) => void;
  onDrawToTable?: (cards: number) => void;
  onDiscard?: (cards: number) => void;
  onDrawFromTable?: (card: number) => void;
}

function Table({
  tableCards,
  deck,
  onReset,
  onDraw,
  onDrawAll,
  onDrawToTable,
  onDiscard,
  onDrawFromTable,
}: TableProps) {
  return (
    <div className="gameTable p-4">
      {deck
        ? (
          <>
            <div className="deckActions">
              <div className="d-flex flex-column">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-light text-nowrap my-1"
                  onClick={() => onDraw(1)}
                >draw card</button>

                <button
                  type="button"
                  className="btn btn-sm btn-outline-light text-nowrap my-1"
                  onClick={() => onDrawAll(1)}
                >deal 1 card each</button>

                { onDiscard && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger text-nowrap my-1"
                    onClick={() => onDiscard(1)}
                  >discard one</button>
                )}
              </div>
            </div>
            <div className="tableActions">
              <div className="d-flex flex-column">
                {onDrawToTable && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-light my-2"
                    onClick={() => onDrawToTable(1)}
                  >draw 1 to table</button>
                )}
              </div>
            </div>
            <div className="stackedCards tightStack d-flex flex-row mr-3">
              <div className="deckCounter">{deck}</div>
              {[...Array(Math.min(deck, 3))].map((_, i, r) => <Card key={(r.length - i).toString()} card={-1} />)}
            </div>
          </>
        ) : <Card key="empty" card={-1} ghost />
      }
      {tableCards.map(card => <Card key={card} card={card} onClick={onDrawFromTable} />)}
      <div className="gameActions">
        <button type="button" className="btn btn-sm btn-outline-primary text-nowrap mx-2" onClick={onReset}>
          reset deck & shuffle
        </button>
      </div>
    </div>
  );
}

export default Table;
