"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const gameActions = {
    CREATE_GAME: "CREATE_GAME",
    RESET_GAME: "RESET_GAME",
    CONNECT_TO_GAME: "CONNECT_TO_GAME",
    GET_ENTIRE_GAME: "GET_ENTIRE_GAME",
    DISCARD_CARD: "DISCARD_CARD",
    DRAW_CARD_TABLE: "DRAW_CARD_TABLE",
    DRAW_CARD: "DRAW_CARD",
    DRAW_CARD_ALL: "DRAW_CARD_ALL",
    PICK_FROM_TABLE: "PICK_FROM_TABLE",
    PUT_TO_TABLE: "PUT_TO_TABLE",
    PICK_FROM_PLAYER: "PICK_FROM_PLAYER",
    GIVE_TO_PLAYER: "GIVE_TO_PLAYER",
    FOLD_HAND: "FOLD_HAND",
    REVEAL_CARD: "REVEAL_CARD",
    UNREVEAL_CARD: "UNREVEAL_CARD",
    COLLECT_REVEALED: "COLLECT_REVEALED",
    ERROR: "GAME_ERROR",
};
exports.default = gameActions;
