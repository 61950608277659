// @ts-ignore
import md5 from 'md5';
import React, { FormEvent } from 'react';

import User from '../user/User';
import type { UserType } from '../types/game.types';


interface EditUserProps {
  onUpdateUser: (userData: UserType) => void;
}

function EditUser(props: EditUserProps) {
  const [userName, setUserName] = React.useState(localStorage.getItem('username') || '');
  const [userEmail, setUserEmail] = React.useState(localStorage.getItem('useremail') || '');
  const [isEdit, setEdit] = React.useState(!userName.length);

  const saveUser = (e: FormEvent) => {
    setEdit(false);
    localStorage.setItem('username', userName);
    localStorage.setItem('useremail', userEmail);

    props.onUpdateUser({ name: userName, emailHash: md5(userEmail) });

    e.preventDefault();
    return false;
  };

  React.useEffect(() => {
    if (userName.length) {
      props.onUpdateUser({ name: userName, emailHash: md5(userEmail) });
    }
  }, []);

  if (isEdit) {
    return (
      <form key="edit-user" className="col-sm-6 col-md-6 col-lg-auto" onSubmit={saveUser}>
        <div className="form-group">
          <label htmlFor="username">Your name:</label>
          <input
            autoFocus
            id="username"
            className="form-control"
            value={userName}
            onChange={(e) => setUserName(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            className="form-control"
            value={userEmail}
            onChange={(e) => setUserEmail(e.currentTarget.value)}
          />
          <small id="emailHelp" className="form-text text-muted">
            * optional; only needed for a gravatar
          </small>
        </div>
        <div className="form-group text-center">
          <button className="btn btn-outline-light">Save user info</button>
        </div>
      </form>
    );
  }

  return (
    <div key="view-user" className="row align-items-center">
      <div className="col-auto">
        <User name={userName} emailHash={md5(userEmail)} size={60} />
      </div>
      <div className="col-auto">
        <button type="button" className="btn btn-outline-light btn-sm" onClick={() => setEdit(true)}>Edit</button>
      </div>
    </div>
  );
}

export default EditUser;
