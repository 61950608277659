import React from 'react';

import './User.scss';

interface UserProps {
  name: string;
  emailHash?: string;
  size?: number;
  points?: number;
  isDealer?: boolean;
  centered?: boolean;
  rightAligned?: boolean;
}

function User({
  name,
  emailHash,
  size = 100,
  points,
  isDealer = false,
  rightAligned = false,
  centered = false,
}: UserProps) {
  return (
    <div className={`user d-flex flex-row${rightAligned ? '-reverse' : ''} ${centered ? 'justify-content-center' : ''} ${isDealer ? 'dealer' : ''}`}>
      <img
        src={`https://www.gravatar.com/avatar/${emailHash}?s=${size * 2}&d=mp`}
        alt="user avatar"
        width={size}
        height={size}
        className={`userImage m${rightAligned ? 'l' : 'r'}-3`}
      />
      <div className={`userName ${size > 50 ? 'large' : 'small'}`}>
        {name || <i>Anonymous</i>}
        {typeof points !== 'undefined' && <div className="userPoints">Hands won: {points}</div>}
      </div>
    </div>
  );
}

export default User;
