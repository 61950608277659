import React, { FormEvent } from 'react';
import { getGameIdFromUrl } from '../utils';


interface JoinProps {
  onJoin: (gameId: string) => void;
}

function GameJoining(props: JoinProps) {
  const [gameId, setGameId] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  const joinGame = (e: FormEvent) => {
    props.onJoin(gameId);

    e.preventDefault();
    return false;
  };

  React.useEffect(() => {
    let gameFromUrl;
    if ((gameFromUrl = getGameIdFromUrl())) {
      setGameId(gameFromUrl);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, []);

  return (
    <form className="col-sm-12 col-md-12 col-lg-auto text-center" onSubmit={joinGame}>
      <h3 className="mb-4">Connect to a game</h3>
      <div className="form-group mb-4 text-left">
        <label>Game id</label>
        <input
          ref={inputRef}
          className="form-control"
          value={gameId}
          onChange={(e) => setGameId(e.currentTarget.value)}
        />
      </div>
      <div className="form-group">
        <button className="btn btn-outline-primary btn-lg px-4">
          Join game
        </button>
      </div>
    </form>
  );
}

export default GameJoining;
