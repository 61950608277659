import React from 'react';

import './Game.scss';
import GameToolbar from './GameToolbar';
import Player from './Player';
import Table from './Table';

import gameActions from '../types/actions';
import type { MsgGeneric, MsgGame, MsgGameCards, MsgPlayerCard } from '../types/actions.types';
import type { GameType, UserType } from '../types/game.types';

import { getGameUrl } from '../utils';
import { playerUIIdx, getPlayerUIPos, getPlayerUIAlignment, getCardsUIPos } from './playerUI';


interface GameProps {
  gameId: string;
  player: UserType;
  gameData: GameType,
  send: (message: MsgGeneric) => void;
  onExitGame: () => void;
}

function Game({
  gameId,
  player,
  gameData: {
    players,
    options,
    deck,
    tableCards,
  },
  send,
  onExitGame,
}: GameProps) {
  // player's position won't (shouldn't) change throughout the game,
  // except if the players array changes in length (players leave or new players join)
  const pIdx = React.useMemo(() => {
    return players.findIndex((p) => p.playerId === player.playerId);
  }, [player.playerId, players.length]);

  // gets the current player object
  const currentPlayer = players[pIdx];
  // gets the other players array, starting with the next player from current
  const otherPlayers = players.slice(pIdx + 1).concat(players.slice(0, pIdx));

  // on mount and unmount (i.e. when gameId changes), update the URL with (or without) the gameId
  React.useEffect(() => {
    window.history.pushState(null, document.title, getGameUrl(gameId));
    return () => {
      window.history.pushState(null, document.title, getGameUrl());
    };
  }, [gameId]);

  return (
    <>
      <div className="gameArea">
        <Table
          deck={deck}
          tableCards={tableCards}
          onReset={() => send({ type: gameActions.RESET_GAME, gameId } as MsgGame)}
          onDraw={(cards) => send({ type: gameActions.DRAW_CARD, gameId, cards } as MsgGameCards)}
          onDrawAll={(cards) => send({ type: gameActions.DRAW_CARD_ALL, gameId, cards } as MsgGameCards)}
          onDrawToTable={options.canDrawToTable ? (cards) => send({ type: gameActions.DRAW_CARD_TABLE, gameId, cards } as MsgGameCards) : undefined}
          onDiscard={options.canDiscardFromDeck ? (cards) => send({ type: gameActions.DISCARD_CARD, gameId, cards } as MsgGameCards) : undefined}
          onDrawFromTable={options.canPickFromTable ? (card) => send({ type: gameActions.PICK_FROM_TABLE, gameId, card } as MsgPlayerCard) : undefined}
        />

        <div style={getPlayerUIPos(0)}>
          <Player
            player={currentPlayer}
            playerAlignment={getPlayerUIAlignment(0)}
            cardsPos={getCardsUIPos(0)}
            isCurrentPlayer
            onHandCardClick={(card: number) => send({ type: gameActions.REVEAL_CARD, gameId, card } as MsgPlayerCard)}
            onRevealedCardClick={(card: number) => send({ type: gameActions.UNREVEAL_CARD, gameId, card } as MsgPlayerCard)}
            onCollectClick={options.canCollectAllRevealed ? () => send({ type: gameActions.COLLECT_REVEALED, gameId } as MsgGame) : undefined}
            onFoldClick={options.canFoldHand ? () => send({ type: gameActions.FOLD_HAND, gameId } as MsgGame) : undefined}
          />
        </div>

        {otherPlayers.map((p, i) => {
          const uiPos = playerUIIdx(otherPlayers.length, i);
          return (
            <div key={p.playerId} style={getPlayerUIPos(uiPos)}>
              <Player
                player={p}
                playerAlignment={getPlayerUIAlignment(uiPos)}
                cardsPos={getCardsUIPos(uiPos)}
                onHandCardClick={
                  options.canPickFromPlayer
                    ? (card: number) => send({ type: gameActions.PICK_FROM_PLAYER, gameId, card, otherPlayerId: p.playerId } as MsgPlayerCard)
                    : undefined
                }
              />
            </div>
          );
        })}
      </div>
      <GameToolbar
        gameId={gameId}
        onExitGame={onExitGame}
        gameOptions={options}
        totalPlayers={players.length}
      />
    </>
  );
}

export default Game;
