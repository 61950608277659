export function getGameIdFromUrl() {
  if (window && window.location && window.location.search) {
    const gameIdParam = window.location.search
      .replace('?', '')
      .split('&')
      .find(param => param.startsWith('gameId='));

    if (gameIdParam) {
      return gameIdParam.replace('gameId=', '');
    }
  }

  return undefined;
}

export function getGameUrl(gameId?: string) {
  return window.location.origin + window.location.pathname + (gameId ? `?gameId=${gameId}` : '');
}
