import React from 'react';

import './Home.scss';
import EditUser from './EditUser';
import GameCreation from './GameCreation';
import GameJoining from './GameJoining';

import type { GameOptionsType, UserType } from '../types/game.types';


interface HomeProps {
  onUpdatePlayer: (playerInfo: UserType) => void;
  preventStart: boolean;
  onCreate: (gameOptions: GameOptionsType) => void;
  onJoin: (gameId: string) => void;
}

function Home(props: HomeProps) {
  return (
    <>
      <div className="container-fluid">
        <div className="row header">
          <header className="col-sm">
            <EditUser onUpdateUser={props.onUpdatePlayer} />
          </header>
        </div>
      </div>

      {!props.preventStart && (
        <div className="container">
          <div className="row content">
            <div className="col-sm">
              <GameJoining onJoin={props.onJoin} />
            </div>
            <div className="col-sm">
              <GameCreation onCreate={props.onCreate} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
