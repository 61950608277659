import React from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import type { MsgGeneric } from '../types/actions.types';

import config from '../_config';

export function useWebService(onDataReceive: (data: MsgGeneric) => void) {
  const wsClient = React.useRef<W3CWebSocket>();

  // a wrapper on top of the send method which stringifies the payload automatically
  const send = React.useCallback(<T>(payload: T) => {
    if (wsClient.current) {
      wsClient.current.send(JSON.stringify(payload))
    }
  }, []);

  // bootstrap the connection to the web service with custom actions  on message received
  const connect = React.useCallback((onOpen?: () => void, onClose?: () => void) => {
    if (!wsClient.current) {
      wsClient.current = new W3CWebSocket(config.ws.url);

      wsClient.current.onopen = () => {
        console.log('WebSocket Client Connected');

        if (typeof onOpen === 'function') {
          onOpen();
        }
      };

      wsClient.current.onmessage = (message) => {
        const dataFromServer = JSON.parse(message.data as string) as MsgGeneric;
        onDataReceive(dataFromServer);
      };

      wsClient.current.onclose = () => {
        console.log('WebSocket Client Disconnected');

        if (typeof onClose === 'function') {
          onClose();
        }
      };
    } else if (typeof onOpen === 'function') {
      onOpen();
    }
  }, [onDataReceive]);

  // a function to request disconnection from the web service
  const disconnect = React.useCallback(() => {
    if (wsClient.current) {
      wsClient.current.close();
      wsClient.current = undefined;
    }
  }, []);

  // automatically disconnect from web service on component unmount
  React.useEffect(() => disconnect, [disconnect]);

  return {
    connect,
    send,
    disconnect,
  };
}
