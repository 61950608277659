import React from 'react';

import './Player.scss';
import User from '../user/User';
import Card from './Card';

import type { PlayerType } from '../types/game.types';
import { UICardPos, UIPlayerAlignment } from './playerUI';


interface PlayerProps {
  player: PlayerType;
  playerAlignment?: UIPlayerAlignment;
  cardsPos?: UICardPos;
  isCurrentPlayer?: boolean;
  onHandCardClick?: (card: number) => void;
  onRevealedCardClick?: (card: number) => void;
  onCollectClick?: () => void;
  onFoldClick?: () => void;
}

function Player({
  player,
  playerAlignment = 'center',
  cardsPos = 'top',
  isCurrentPlayer = false,
  onHandCardClick,
  onRevealedCardClick,
  onCollectClick,
  onFoldClick,
}: PlayerProps) {
  return (
    <div className={`player d-flex flex-column${cardsPos === 'top' ? '-reverse' : ''}`}>
      <User
        name={player.name}
        emailHash={player.emailHash}
        size={48}
        points={player.score}
        isDealer={player.isDealer}
        rightAligned={playerAlignment === 'right'}
        centered={playerAlignment === 'center'}
      />
      <div className={`mt-3 mb-3 d-flex align-items-center flex-row${playerAlignment === 'right' ? '-reverse' : ''}`}>
        {isCurrentPlayer && (player.hand.length + player.revealed.length) > 0 && onFoldClick && (
          <div className="handActions p-3">
            <div className="d-flex flex-column justify-content-center">
              <button type="button" className="btn btn-outline-danger btn-sm" onClick={onFoldClick}>fold hand</button>
            </div>
          </div>
        )}
        <div className={`stackedCards d-flex flex-row ${isCurrentPlayer ? 'handCards p-3 mr-3' : ''}`}>
          {player.hand.map((card, i) => (
            <Card
              key={`${card}_${i}`}
              card={card}
              onClick={onHandCardClick && (isCurrentPlayer ? onHandCardClick : () => onHandCardClick(i))}
            />
          ))}
        </div>
        {player.revealed.length > 0 && (
          <>
            <div className="stackedCards d-flex flex-row mx-3">
              {player.revealed.map((card) => (
                <Card
                  key={card}
                  card={card}
                  onClick={onRevealedCardClick} // todo: pick from other player's revealed
                />
              ))}
            </div>
            {isCurrentPlayer && onCollectClick && (
              <div className="revealedActions">
                <div className="d-flex flex-column justify-content-center">
                  <button type="button" className="btn btn-outline-light btn-sm" onClick={onCollectClick}>collect hand</button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Player;
