import React from 'react';

import './Card.scss';

const cards = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
const colors = ['♦️', '♣️', '♥️', '♠️'];

interface CardProps {
  card: number;
  onClick?: (c: number) => void;
  ghost?: boolean;
}

function Card({ card, onClick, ghost = false }: CardProps) {
  const colorIndex = Math.floor(card / 13);

  return (
    <div
      onClick={() => onClick && onClick(card)}
      className={`playerCard ${card < 0 ? 'unknown' : ''} ${colorIndex % 2 ? 'black' : 'red'} ${onClick ? 'clickable' : ''} ${ghost ? 'ghost' : ''}`}
    >
      {card >= 0
        ? (
          <div className="cardNumber">
            <div>{cards[card % 13]}</div>
            <div>{colors[colorIndex]}</div>
          </div>
        ) : ''
      }
    </div>
  );
}

export default Card;
